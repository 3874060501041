import React from "react";

const Logo: React.FC = () => {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 502.81 435.45">
      <title>logo</title>
      <polygon fill="#3f88c5" points="251.41 4 3.46 433.45 499.35 433.45 251.41 4" />
      <path fill="#ffffff" d="M502.81,435.45H0l1.73-3L251.41,0Zm-495.88-4h489L251.41,8Z" />
      <path
        fill="#ffffff"
        d="M221.19,107.2c6-9.43,25.61-40.46,31.25-49.35l6.93,13.23,100.41,191.6L244.06,79.92h15.31l-19.84,37.86L221.19,107.2Z"
      />
      <path
        fill="#ffffff"
        d="M427.53,358.84,453.22,408l-15.08-.63-214.32-8.93,214.32-8.94-7.74,13.41-21.21-33.43,18.34-10.59Z"
      />
      <path fill="#ffffff" d="M106.66,409,49.59,406.6l8-12.64,114.17-180L73,402.82l-7.68-13.3,41.37-1.72V409Z" />
      <polygon
        fill="#ffffff"
        points="265.43 242.55 265.43 317.32 278.28 323.02 278.28 339.52 224.54 339.52 224.54 323.02 237.39 317.32 237.39 242.55 224.54 236.13 224.54 220.36 278.28 220.36 278.28 236.13 265.43 242.55"
      />
    </svg>
  );
  /* eslint-enable */
};

export default Logo;
