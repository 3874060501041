import React from "react";
import { useFela } from "react-fela";
import { Link } from "react-router-dom";
import Icon from "../Icon";

import styles from "./styles";

const Header: React.FC = () => {
  const { css } = useFela();

  return (
    <header className={css(styles.wrapper)}>
      <Link to="/" className={css(styles.brand)}>
        <Icon icon="logo" />
        <h1>AI COMM LABS</h1>
      </Link>
    </header>
  );
};

export default Header;
