import React from "react";

const SolarSystem: React.FC = () => {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243 230.76">
      <path
        d="M76.53,22.79c53.13-29,123.11-8.27,153.14,44.62,19.85,33.41,23.31,74.34,4.09,108.92a104.57,104.57,0,0,1-29.09,33.77,81.9,81.9,0,0,1-19.26,10.45l-.93-1.55A137.38,137.38,0,0,0,199,204a127.33,127.33,0,0,0,25.91-53.28c5.21-25.42,4.13-52.84-8-76.35C198.71,38,158.67,14.6,117.88,15.84a113.13,113.13,0,0,0-40.57,8.59l-.78-1.64Z"
        transform="translate(-3.5 -9.62)"
        style={{ fill: "#494949" }}
      />
      <path
        d="M171.35,228.25c-52.66,27.43-120.94,6.63-150.67-45.06C1,150.46-3,110.5,15.19,76.11c6.86-13,16-24.89,27.64-34A77.59,77.59,0,0,1,61.57,31.45l1,1.53A126,126,0,0,0,48.65,48a131.19,131.19,0,0,0-24,53.12c-4.65,25.07-3.3,51.94,8.68,75,21.32,42.13,71.24,65.69,117.53,56.15a111.73,111.73,0,0,0,19.75-5.67l.75,1.65Z"
        transform="translate(-3.5 -9.62)"
        style={{ fill: "#5e5e5e" }}
      />
      <path
        d="M59.1,127C60.24,79,105.77,44.46,151.9,62c26.51,9.78,45,36.68,45.36,64.93,1.32,52.27-51.88,90.63-100,67-24.72-12.43-38.86-40.12-38.15-67Zm1.82,0c.68,34.09,31.6,57.34,64.09,55.94,39.9-1.45,68.62-40,53.92-78.28-15.62-40.27-66.71-50.25-98.54-22.28C67.91,93.56,60.4,110.11,60.92,127Z"
        transform="translate(-3.5 -9.62)"
        style={{ fill: "#494949" }}
      />
      <circle cx="201.5" cy="196.58" r="26.52" style={{ fill: "#358cc6" }} />
      <circle cx="41.09" cy="37.47" r="26.52" style={{ fill: "#358cc6" }} />
      <circle cx="121.51" cy="117.02" r="31.05" style={{ fill: "#358cc6" }} />
      <circle cx="169.8" cy="69.16" r="24.58" style={{ fill: "#358cc6" }} />
    </svg>
  );
  /* eslint-enable */
};

export default SolarSystem;
