import React from "react";

const CircuitBrain: React.FC = () => {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.29 241.36">
      <path
        d="M117.44,220c-22.68,55.45-52.93,3.78-52.93,3.78-1-.06-2-.13-3-.22-41.89-3.87-31-37.58-31-37.58C-21.17,144.41,28,115.43,28,115.43,6.55,76.37,43.09,65,43.09,65,40.57,34.78,58.7,33.56,64.39,30.46a5,5,0,0,0,2.08-2.15C69.19,23,74.6,2,94.76,4.54c17.5,2.19,22.68,25.2,22.68,25.2Z"
        transform="translate(-6.86 -4.32)"
        style={{ fill: "#555" }}
      />
      <path
        d="M132.56,220c22.68,55.44,52.93,3.78,52.93,3.78,1-.06,2-.13,3-.22,41.89-3.87,31-37.59,31-37.59,51.66-41.58,2.52-70.56,2.52-70.56C243.45,76.35,206.91,65,206.91,65c2.52-30.24-15.61-31.46-21.3-34.56a5,5,0,0,1-2.08-2.15C180.81,23,175.4,2,155.24,4.53c-17.5,2.19-22.68,25.2-22.68,25.2Z"
        transform="translate(-6.86 -4.32)"
        style={{ fill: "#555" }}
      />
      <rect x="109.95" y="25.42" width="16.38" height="189.02" style={{ fill: "#2c88c4" }} />
      <path
        d="M180.45,95.91H162.8V90.23c0-6.45-4.34-8.34-6-8.82H93.5V63.77h65.06l.78.14a26.1,26.1,0,0,1,21.11,26.32Z"
        transform="translate(-6.86 -4.32)"
        style={{ fill: "#2c88c4" }}
      />
      <polygon points="65.22 166.66 65.22 166.56 82.86 166.56 65.22 166.66" />
      <path
        d="M89.72,157.66H72.07v-4.43c0-9.79,2.73-17.3,8.1-22.32a21,21,0,0,1,14.77-5.71H126.2v17.64H94.32c-2.88.19-4.6,4.06-4.6,10.39Z"
        transform="translate(-6.86 -4.32)"
        style={{ fill: "#2c88c4" }}
      />
      <rect x="118.69" y="165.7" width="44.23" height="17.64" style={{ fill: "#2c88c4" }} />
      <circle cx="72.78" cy="68.27" r="13.86" style={{ fill: "#fff" }} />
      <path
        d="M79.64,92.12A19.54,19.54,0,1,1,99.17,72.59,19.56,19.56,0,0,1,79.64,92.12Zm0-27.72a8.19,8.19,0,1,0,8.19,8.19A8.2,8.2,0,0,0,79.64,64.4Z"
        transform="translate(-6.86 -4.32)"
        style={{ fill: "#2c88c4" }}
      />
      <circle cx="164.14" cy="105.44" r="13.86" style={{ fill: "#fff" }} />
      <path
        d="M171,129.29a19.53,19.53,0,1,1,19.54-19.53A19.55,19.55,0,0,1,171,129.29Zm0-27.72a8.19,8.19,0,1,0,8.2,8.19A8.2,8.2,0,0,0,171,101.57Z"
        transform="translate(-6.86 -4.32)"
        style={{ fill: "#2c88c4" }}
      />
      <circle cx="73.41" cy="167.19" r="13.86" style={{ fill: "#fff" }} />
      <path
        d="M80.27,191A19.54,19.54,0,1,1,99.8,171.51,19.56,19.56,0,0,1,80.27,191Zm0-27.73a8.2,8.2,0,1,0,8.19,8.2A8.21,8.21,0,0,0,80.27,163.31Z"
        transform="translate(-6.86 -4.32)"
        style={{ fill: "#2c88c4" }}
      />
      <circle cx="176.74" cy="173.49" r="13.86" style={{ fill: "#fff" }} />
      <path
        d="M183.6,197.34a19.54,19.54,0,1,1,19.53-19.53A19.56,19.56,0,0,1,183.6,197.34Zm0-27.72a8.19,8.19,0,1,0,8.19,8.19A8.2,8.2,0,0,0,183.6,169.62Z"
        transform="translate(-6.86 -4.32)"
        style={{ fill: "#2c88c4" }}
      />
    </svg>
  );
  /* eslint-enable */
};

export default CircuitBrain;
