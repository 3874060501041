import React from "react";

const RadarChart: React.FC = () => {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 242.79 219">
      <path
        d="M169.44,234.5H80.56a32.61,32.61,0,0,1-28.17-16.26L8,141.26a32.61,32.61,0,0,1,0-32.52l44.44-77A32.61,32.61,0,0,1,80.56,15.5h88.88a32.61,32.61,0,0,1,28.17,16.26l44.44,77a32.61,32.61,0,0,1,0,32.52l-44.44,77A32.61,32.61,0,0,1,169.44,234.5ZM80.56,38.92a9.14,9.14,0,0,0-7.88,4.55l-44.45,77a9.13,9.13,0,0,0,0,9.1l44.45,77a9.14,9.14,0,0,0,7.88,4.55h88.88a9.14,9.14,0,0,0,7.88-4.55l44.45-77a9.13,9.13,0,0,0,0-9.1l-44.45-77a9.14,9.14,0,0,0-7.88-4.55Z"
        transform="translate(-3.61 -15.5)"
        style={{ fill: "#555" }}
      />
      <path
        d="M166.51,167.34l-89-21.06,52.37-75Zm-63.7-30.72L142,145.9l-16.15-42.33Z"
        transform="translate(-3.61 -15.5)"
        style={{ fill: "#2c88c4" }}
      />
      <circle cx="122.57" cy="67.34" r="19.32" style={{ fill: "#2c88c4" }} />
      <circle cx="82.75" cy="124.72" r="19.32" style={{ fill: "#2c88c4" }} />
      <circle cx="154.19" cy="141.12" r="19.32" style={{ fill: "#2c88c4" }} />
    </svg>
  );
  /* eslint-enable */
};

export default RadarChart;
