import chroma from "chroma-js";
import {
  Breakpoints,
  ButtonTypes,
  Colors,
  Container,
  Image,
  Theme,
} from "./Theme";

const colors: Colors = {
  // Brand Colors
  brandLight: "#1F71B6",
  brandDark: "#074578",

  // Utility Colors
  success: "#4CAF50",
  successLight: "#7AC07D",
  danger: "#F44336",
  dangerLight: "#F48794",
  caution: "#F4D03F",
  warning: "#FF9800",

  blue: "#0d6efd",

  // Backgrounds
  background: "#F2F2F2",

  // Neutrals
  gray100: "#FAFAFA",
  gray200: "#F2F2F2",
  gray300: "#E5E5E5",
  gray400: "#C4C4C4",
  gray500: "#A7A7A7",
  gray600: "#808080",
  gray700: "#4F4F4F",
  gray750: "#25292E",
  gray800: "#1D2129",
  black: "#06060e",
};

const breakpoints: Breakpoints = {
  small: "@media (min-width: 480px)",
  medium: "@media (min-width: 768px)",
  large: "@media (min-width: 992px)",
  xlarge: "@media (min-width: 1200px)",
};

const container: Container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  [breakpoints.small]: {
    padding: "24px",
  },
  [breakpoints.medium]: {
    padding: "24px",
    width: "750px",
  },
  [breakpoints.large]: {
    padding: "24px",
    width: "970px",
  },
  [breakpoints.xlarge]: {
    padding: "24px",
    width: "1170px",
  },
};

const buttonTypes: ButtonTypes = {
  primary: {
    backgroundColor: colors.brandLight,
    hoverBackgroundColor: chroma(colors.brandLight).darken().hex(),
    color: "white",
    hoverColor: "white",
    borderColor: "transparent",
    minWidth: "110px",
  },
  secondary: {
    backgroundColor: colors.gray800,
    hoverBackgroundColor: chroma(colors.gray800).darken().hex(),
    color: "white",
    hoverColor: "white",
    borderColor: "transparent",
    minWidth: "110px",
  },
  delete: {
    backgroundColor: colors.danger,
    hoverBackgroundColor: chroma(colors.danger).darken().hex(),
    color: "white",
    hoverColor: "white",
    borderColor: colors.danger,
    minWidth: "110px",
  },
  plain: {
    backgroundColor: "transparent",
    hoverBackgroundColor: "transparent",
    color: colors.gray500,
    hoverColor: chroma(colors.gray500).darken().hex(),
    borderColor: "transparent",
    minWidth: 0,
  },
};

const globalBorderRadius = "3px";

const image: Image = {
  display: "block",
  maxWidth: "100%",
  height: "auto",
};

const theme: Theme = {
  breakpoints,
  buttonTypes,
  colors,
  container,
  globalBorderRadius,
  image,
};

export default theme;
