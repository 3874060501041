import { Theme } from "../../theme/Theme";

type Styles = {
  container: object;
  hero: object;
  servicesWrapper: object;
  serviceList: object;
  serviceTile: object;
};

const container = ({ theme }: { theme: Theme }) => {
  return {
    ...theme.container,
  };
};

const hero = ({ theme }: { theme: Theme }) => {
  return {
    backgroundColor: theme.colors.black,
    height: "calc(100vh - 82px)",
    position: "relative",
    "& div": {
      color: "white",
      position: "absolute",
      zIndex: 2,
      display: "flex",
      alignItems: "center",
      width: "80vw",
      height: "70%",
      margin: "0 10%",
      "& h1": {
        fontSize: "2em",
        width: "100%",
      },
    },

    "& img": {
      maxHeight: "calc(100vh - 82px)",
      position: "absolute",
      right: 0,
      zIndex: 1,
    },
  };
};

const servicesWrapper = ({ theme }: { theme: Theme }) => ({
  ...theme.container,
  paddingTop: "50px",
  textAlign: "center",
});

const serviceList = () => ({
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
});

const serviceTile = () => ({
  width: "210px",
  maxWidth: "100%",
  padding: "20px",
  margin: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  "> .icon > svg": {
    height: "100px",
  },

  "> p": {
    width: "250px",
    marginTop: 0,
  },
});

const styles: Styles = {
  container,
  hero,
  servicesWrapper,
  serviceList,
  serviceTile,
};

export default styles;
