import { Route, Routes } from "react-router-dom";
import Header from "./common/Header";
import NotFound from "./common/NotFound";

import Home from "./pages/Home";

function App() {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
