import React from "react";
import { IconProps } from "..";

const Loading: React.FC<IconProps> = ({ fill }) => {
  /* eslint-disable max-len */
  return (
    <svg style={{ fill }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.92 128" height="100">
      <title>loading</title>
      <g id="StZm6I.tif">
        <path
          d="M8.7,64C9,90.16,27.52,112.91,53.08,118A88.37,88.37,0,0,0,63.66,119c3.5.26,5.63,2.93,4.59,6a4.36,4.36,0,0,1-4.37,3,62.37,62.37,0,0,1-38.51-13C11.73,104.51,3.22,90.74.83,73.74-2.51,49.89,5.25,29.9,23.72,14.42,36.28,3.88,51.09-.68,67.44.08a63.91,63.91,0,0,1,59,49.89A99.29,99.29,0,0,1,128,63.5a4.36,4.36,0,0,1-4.38,5,4.51,4.51,0,0,1-4.5-4.8,51.46,51.46,0,0,0-4-20.32c-8.61-19.64-23.48-31.43-44.83-34C41.76,6,16.1,24.77,10.13,52.84,9.35,56.49,9.16,60.26,8.7,64Z"
          transform="translate(-0.08 0)"
        />
        <path
          d="M107.52,63.88c.15,21.55-16.43,40.51-37.77,43.12A43.28,43.28,0,0,1,21.6,73,42.34,42.34,0,0,1,23,50c.58-1.73,1.33-2.29,2.49-1.94s1.5,1.35.91,3.1A39.82,39.82,0,1,0,70.58,24.7c-1.58-.27-2.37-1.23-2-2.37a1.86,1.86,0,0,1,2.29-1.2C89.57,23.88,104.76,39.41,107,58.06,107.25,60,107.36,61.94,107.52,63.88Z"
          transform="translate(-0.08 0)"
        />
        <path
          d="M44.39,62.86c.37-7,4.09-13.27,11.72-16.64,7.43-3.29,14.45-2.09,20.75,3.06.54.44,1.47.91.73,1.78s-1.33,0-1.87-.44c-5.41-4.4-11.45-5.6-18-3.15A17.07,17.07,0,0,0,46.41,61.28a17.88,17.88,0,0,0,34.4,9.09c.27-.72.26-2,1.46-1.62,1.35.46.41,1.55.15,2.28a19.71,19.71,0,0,1-22,12.45C51.27,81.89,44.35,73.52,44.39,62.86Z"
          transform="translate(-0.08 0)"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
};

export default Loading;
