import React from "react";

const ComputerPhone: React.FC = () => {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.24 205.58">
      <path
        d="M182.14,214.68a10,10,0,0,1-10,10h-107a10,10,0,0,1,0-19.93H92.67l6.24-17.83h39.54l6.24,17.83h27.48A10,10,0,0,1,182.14,214.68Z"
        transform="translate(-4.88 -22.21)"
        style={{ fill: "#2c88c4" }}
      />
      <rect width="225.5" height="164.67" rx="19.24" style={{ fill: "#444" }} />
      <rect x="25.17" y="25.17" width="177.26" height="109.08" style={{ fill: "#2c88c4" }} />
      <rect x="176.26" y="102.35" width="63.98" height="103.23" rx="12" style={{ fill: "#444" }} />
      <rect x="188.63" y="115.25" width="39.25" height="65.59" style={{ fill: "#2c88c4" }} />
      <rect x="198.3" y="189.45" width="20.97" height="7.53" rx="2.97" style={{ fill: "#2c88c4" }} />
    </svg>
  );
  /* eslint-enable */
};

export default ComputerPhone;
