import React from "react";
import { IconProps } from "..";

const CheckCircle: React.FC<IconProps> = ({ fill }) => {
  /* eslint-disable max-len */
  return (
    <svg style={{ fill }} width="30" height="30" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
      <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z" />
    </svg>
  );
  /* eslint-enable */
};

export default CheckCircle;
