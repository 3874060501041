import React from "react";
import { IconProps } from "..";

const Logout: React.FC<IconProps> = ({ fill }) => {
  /* eslint-disable max-len */
  return (
    <svg style={{ fill }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M16 9v-4l8 7-8 7v-4h-8v-6h8zm-16-7v20h14v-2h-12v-16h12v-2h-14z" />
    </svg>
  );
  /* eslint-enable */
};

export default Logout;
