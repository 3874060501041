import React from "react";

interface OwnProps {
  icon: string;
  className?: string;
  fill?: string;
}

export interface IconProps {
  fill?: string;
}

const Icon: React.FC<OwnProps> = props => {
  const IconComponent = require(`./icons/${props.icon}`).default;

  return (
    <span className={`icon ${props.className}`} aria-hidden={true}>
      <IconComponent fill={props.fill} />
    </span>
  );
};

export default Icon;
