import { Theme } from "../../theme/Theme";

type Styles = {
  wrapper: object;
  brand: object;
};

const wrapper = ({ theme }: { theme: Theme }) => {
  return {
    backgroundColor: theme.colors.black,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    "& h1": {
      color: "white",
      padding: "30px",
      margin: 0,
      fontSize: "18px",
    },

    "& a": {
      color: "white",
      textDecoration: "none",
      fontWeight: "bold",
      padding: "0 20px",
      fontSize: "14px",
    },
  };
};

const brand = {
  display: "flex",
  alignItems: "center",

  "&  svg": {
    height: "50px",
  },
};

const styles: Styles = {
  wrapper,
  brand,
};

export default styles;
