import React from "react";
import { useFela } from "react-fela";
import hero from "../../assets/background.jpg";
import Icon from "../../common/Icon";
import styles from "./styles";

const Home: React.FC = () => {
  const { css } = useFela();

  return (
    <>
      <div className={css(styles.hero)}>
        <div className={css(styles.container)}>
          <h1>
            Intelligent digital solutions
            <br />
            to meet your business needs
          </h1>
        </div>
        <img className="img-responsive" alt="" src={hero} />
      </div>
      <main id="main">
        <section className={css(styles.servicesWrapper)}>
          <h2>Connect with your customers like never before</h2>
          <div className={css(styles.serviceList)}>
            <div className={css(styles.serviceTile)}>
              <Icon icon="solar-system" />
              <h3>Bespoke Services</h3>
              <p>Custom development that is tailored to you</p>
            </div>
            <div className={css(styles.serviceTile)}>
              <Icon icon="circuit-brain" />
              <h3>Intelligent Communication</h3>
              <p>Smart IVR systems and automated answering services</p>
            </div>
            <div className={css(styles.serviceTile)}>
              <Icon icon="radar-chart" />

              <h3>Custom Web Applications</h3>
              <p>Streamline and automate your business processes</p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
